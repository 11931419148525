import React, {forwardRef} from 'react';

import '../../assets/style/component/serverballs/index.scss';

function ServerBalls(props, ref){

    const sizeBallMove = 40;
    const sizeTotal = -75 * 40;
    const current = props.gameBallsServer.current;
    const balls = props.balls.map(item => item);
    balls.reverse();

    return(
        <div style={{left:`${sizeTotal + (current * sizeBallMove)}px`}} className="component-serverballs">
            {
                balls.map((ball, id) =>{
                    return <span className={balls.length - id === current ? 'current' : (balls.length - id < current ? 'active' : '') + ' ' +('ball-color-'+ Math.ceil(ball/15))} key={id}>{ball}</span>
                })
            }
        </div>
    )
}

export default forwardRef(ServerBalls);