import React, {useState} from 'react';

function Clock(props){

    const [timer, updateTime] = useState('00:00:00');

    function serverUpdate(){
        const data = new Date();
        const h = data.getHours() < 10 ? `0${data.getHours()}` : data.getHours();
        const m = data.getMinutes() < 10 ? `0${data.getMinutes()}` : data.getMinutes();
        const s = data.getSeconds() < 10 ? `0${data.getSeconds()}` : data.getSeconds();
        updateTime(`${h}:${m}:${s}`);
    }

    switch(props.type){
        case 'server':
            setInterval(serverUpdate, 50);
        break;
        default:

    }



    return(
        <span>{timer}</span>
    );

}

export default Clock;