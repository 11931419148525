import React, {useState, useEffect} from 'react';
import {login} from '../../service/auth';

import '../../assets/style/page/login/index.scss';
import { Link } from 'react-router-dom';

function Login(props){

    
    const session_id = '3q1p05qtbgooatcqiz0glofp';
    const user_login = 'Donan';
    const user_pass = '123123'
    
    const [isUserLogin, setUserLogin] = useState(true);
    login('TESTE');
    
    useEffect(()=>{
        var headers = new Headers();
        headers.append("Content-Type", "application/x-www-form-; charset=UTF-8");
        headers.append("Access-Control-Allow-Origin","*");
        headers.append('Access-Control-Allow-Methods', 'GET, HEAD, POST, PUT, DELETE, CONNECT, OPTIONS, TRACE, PATCH');
        headers.append('Access-Control-Allow-Headers', 'X-Custom-Header, tatus,cache-control,content-type,content-encoding,vary,server,x-aspnet-version,x-powered-by,date,content-length');
        headers.append('Access-Control-Expose-Headers', 'status, cache-control, content-type, content-encoding, vary, server, x-aspnet-version, x-powered-by, date, content-length');

        // var params = { 
        //                 method: 'GET',
        //                 headers: headers,
        //                 mode: 'cors',
        //                 cache: 'default'
        //             };
        const url = `http://www.bingoemcasa.site//webservices/dataservices.asmx/Login?session=${session_id}&login=${user_login}&pass=${user_pass}`;
        fetch(url).then(data => {
            login('TESTE');
            setUserLogin(true);
        });
    }, []);

    

    return(
        <section className="page-login">
            <div className="box">
                <header>
                    <svg width="100px" height="100px" viewBox="0 0 300 300">
                        <path className="fil0 str0" d="M150 14c10,0 20,1 30,3 5,26 8,52 10,77 -27,-2 -54,-1 -80,1 2,-26 6,-52 12,-78 9,-2 18,-3 28,-3zm40 95c2,28 1,55 -1,81 -26,2 -54,3 -82,2 -2,-27 -1,-54 1,-82 29,-2 56,-2 82,-1zm-3 101c-3,26 -7,52 -13,77 -8,2 -16,3 -24,3 -10,0 -20,-2 -30,-4 -6,-25 -10,-52 -12,-78 26,3 52,3 79,2zm0 -191c44,12 80,46 94,90 -27,-7 -55,-11 -81,-14 -4,-25 -8,-50 -13,-76zm98 103c2,10 3,20 3,30 0,8 -1,17 -2,25 -27,5 -54,9 -82,12 1,-28 0,-53 -3,-79 28,3 56,7 84,12zm-4 74c-14,40 -46,73 -87,86 5,-26 8,-50 9,-74 26,-2 52,-6 78,-12zm-176 86c-42,-15 -75,-49 -87,-92 26,8 52,13 78,17 2,25 5,50 9,75zm-89 -101c-3,-9 -4,-19 -4,-29 0,-9 1,-18 3,-26 29,-7 57,-12 82,-15 -2,27 -2,53 -1,80 -26,-1 -52,-5 -80,-10zm2 -70c13,-43 48,-77 91,-91 -5,25 -8,50 -11,76 -27,3 -53,8 -80,15z"/>
                    </svg>
                </header>
                <p>
                    Usuário não autenticado favor efetuar login novamente.
                </p>
                <p>
                    <a rel="noopener noreferrer" href="http://www.bingoemcasa.site/" target="_blank">Clique aqui</a> para acessar.
                </p>
                <p>
                    {
                        isUserLogin ?
                            <Link to="/Bingo">LOGIN PARA TESTE</Link>
                        :
                        ''
                    }
                </p>
            </div>
        </section>
    )

}

export default Login;