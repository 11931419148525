import React from 'react';
import ReactDOM from 'react-dom';

import Routes from './configuration/routes';

import * as serviceWorker from './serviceWorker';

import './assets/style/app.scss';

ReactDOM.render(<React.StrictMode><Routes /></React.StrictMode>,document.getElementById('root'));

serviceWorker.unregister();
