import React from 'react';
import {Icon} from 'react-icons-kit';
import {star} from 'react-icons-kit/fa/star';

import '../../assets/style/component/card/index.scss';

function Card(props){

    console.log(props);

    const numbersCard = props.numbers ? props.numbers : [1,2,3,4,5,6,7,8,9,10,11,12, 0,13,14,15,16,17,18,19,20,21,22,23,24];
    //numbersCard.sort();

    function checkNumber(number){

        if(!props.gameBallsServer)
            return '';

        const isValid = props.gameBallsServer.balls.filter(item => {
            return number === item;
        });

        return isValid.length == 1 ? 'active' : '';
    }

    return(
        <div className="component-card">
            <header>
                <div>B</div>
                <div>I</div>
                <div>N</div>
                <div>G</div>
                <div>O</div>
            </header>
            <section>
                {
                    numbersCard.map((item, id)=>{
                        return <div key={`label-${id}`} className={`boxid-${id} ${checkNumber(item)}`}>{id === 12 ? 
                            <Icon size="20" icon={star} /> : 
                            <>{item}
                            <svg viewBox="0 0 100 100" className="check">
                            <path d={"M34.745,7.183C25.078,12.703,13.516,26.359,8.797,37.13 c-13.652,31.134,9.219,54.785,34.77,55.99c15.826,0.742,31.804-2.607,42.207-17.52c6.641-9.52,12.918-27.789,7.396-39.713 C85.873,20.155,69.828-5.347,41.802,13.379"} ></path>
                            </svg></>
                        }</div>
                    })
                }
            </section>
            <footer></footer>
        </div>
    );

}

export default Card;