import React, {useState, useEffect,  useRef, useCallback} from 'react';
import {Icon} from 'react-icons-kit';
import {powerOff} from 'react-icons-kit/fa/powerOff';
import {volumeOff} from 'react-icons-kit/fa/volumeOff';
import {volumeUp} from 'react-icons-kit/fa/volumeUp';
import {commentingO} from 'react-icons-kit/fa/commentingO';
import {userCircleO} from 'react-icons-kit/fa/userCircleO';
import {shoppingCart} from 'react-icons-kit/fa/shoppingCart';
import {trophy} from 'react-icons-kit/fa/trophy';
import {ticket} from 'react-icons-kit/fa/ticket';

import Clock from '../../component/clock';
import Card from '../../component/card';
import ServerBalls from '../../component/serverballs';

import '../../assets/style/page/bingo/index.scss';

import logo from '../../assets/image/logo_fill.svg';

import audioNumbers from '../../assets/sound/numbers.mp3';

function Bingo(props){

    const timeAudioPos = [0.91, 1.79, 3.06, 4.20, 5.29, 6.40, 7.64, 8.78, 9.80, 10.89,
	12.10, 13.17, 14.28, 15.42, 16.79, 17.90, 19.41, 20.80, 22.14, 23.55,
	24.75, 26.10, 27.64, 29.13, 30.63, 32.21, 33.81, 35.35, 36.88, 38.27,
	39.34, 40.65, 42.20, 43.75, 45.21, 46.80, 48.41, 49.98, 51.45, 52.95,
	54.19, 55.53, 57.14, 58.80, 60.35, 61.99, 63.67, 65.25, 66.78, 68.33,
	69.77, 71.41, 73.32, 75.27, 77.11, 78.97, 80.92, 82.82, 84.64, 86.51,
	87.90, 89.42, 91.23, 93.07, 94.83, 96.65, 98.51, 100.31, 102.02, 103.77,
	105.21, 106.86, 108.76, 110.71, 112.26];

    const timeAudioPosDuration = [400, 750, 615, 580, 620, 730, 645, 540, 600, 730,
                            570, 650, 660, 880, 630, 1010, 910, 930, 900, 700,
                            885, 1065, 1015, 1000, 1130, 1108, 1073, 1044, 915, 
                            580, 800, 1068, 1060, 970, 1115, 1115, 1065, 985, 985, 
                            710, 860, 1111, 1170, 1027, 1184, 1180, 1100, 1027, 1050, 
                            950, 1175, 1460, 1470, 1338, 1370, 1477, 1454, 1381, 1407, 
                            911, 1036, 1320, 1361, 1271, 1364, 1399, 1306, 1256, 1285, 
                            985, 1146, 1425, 1515, 1100, 1518];

    const currentTime = 2000;                        

    const audio_numbers = new Audio(audioNumbers);

    const functionsupdate = new Set();
    
                        
    const [isAudio, controllerAudio] = useState(true);
    const [gameData, setGameData] = useState(null);
    const [gameBallsServer, setGameBalls] = useState({current:-1, balls:[]});

    const serverballs = useRef(null);
    let currentBallServer = -1;
    let lastBall = 75;

   // let updateBalls = [];

    //const session_id = '3q1p05qtbgooatcqiz0glofp';
    
    function initNewGame(){
        if(gameData){
           lastBall = ~~gameData.BingoInfoTO.currentGame[0].winner_num_balls[0];
           console.log(lastBall);
            currentBallServer = gameBallsServer.current + 1;

            setTimeout(getBallServer, currentTime);
        }
        
    }

    functionsupdate.add(initNewGame);

    function getBallServer(){
        if(gameData){
            const currentBall = gameData.BingoInfoTO.serverBalls[0].unsignedByte[currentBallServer];
            console.log('Bola SORTEADA',currentBall );
            console.log(currentBallServer, lastBall);
            
            if(timeAudioPos[currentBall - 1]){
                audio_numbers.currentTime = timeAudioPos[currentBall - 1];
                audio_numbers.play();
                setTimeout(()=>{
                    audio_numbers.pause();
                }, timeAudioPosDuration[currentBall - 1]);
            }
            
            const balls =gameData.BingoInfoTO.serverBalls[0].unsignedByte.filter((item, id) =>{ if(id <= currentBallServer) return item});
            setGameBalls({current:currentBallServer + 1, balls:balls});
            currentBallServer +=1;
            //serverballs.updateBalls(currentBallServer);
            if(currentBallServer < lastBall + 1){
                //const currentTime = currentBallServer > 0 ? timesUpdateBall[currentBallServer] - timesUpdateBall[currentBallServer - 1] : timesUpdateBall[currentBallServer];
                setTimeout(getBallServer,  currentTime);
            }
        }
    }


    useEffect(()=>{
        var headers = new Headers();
        headers.append("Content-Type", "application/x-www-form-; charset=UTF-8");
        headers.append("Access-Control-Allow-Origin","*");
        headers.append('Access-Control-Allow-Methods', 'GET, HEAD, POST, PUT, DELETE, CONNECT, OPTIONS, TRACE, PATCH');
        headers.append('Access-Control-Allow-Headers','X-Custom-Header, tatus,cache-control,content-type,content-encoding,vary,server,x-aspnet-version,x-powered-by,date,content-length');
        headers.append('Access-Control-Expose-Headers', 'status, cache-control, content-type, content-encoding, vary, server, x-aspnet-version, x-powered-by, date, content-length');

        // var params = { 
        //                 method: 'GET',
        //                 headers: headers,
        //                 mode: 'cors',
        //                 cache: 'default'
        //             };
        //const url = 'http://192.168.15.37/bingoemcasa/data_test.php';
        //const url = `http://www.bingoemcasa.site/webservices/dataservices.asmx/Bingo_EnterAndGetInformation?session=${session_id}`;
        const url = 'http://bingoemcasa.wa2.com.br/data_test.php';
        fetch(url).then(data => {
            data.text().then(xml => {
                const xmlParse = require('xml2js').parseString;
                xmlParse(xml, (err, result) => {
                    console.log(result);
                    setGameData(result);
                });
            });
        });
    }, []);

    useEffect(()=>{ initNewGame() },[gameData]);
    


    const convertToMoney = (value) => {
        let real = value.toString().split('.')[0];
        let cents = value.toString().split('.')[1];
        if(cents){
            cents = cents.length === 1 ? `0${cents}` : cents;
        }else{
            cents = '00';
        }
        return `R$ ${real},${cents}`;
    };

    return(
        <section className="page-bingo">
            <div className="bar-top">
                <span></span>
                <span className="clock"><Clock type="server" /></span>
                <span className="area-buttons">
                    <Icon size="20" className="button-chat" icon={commentingO} />
                    <Icon onClick={()=> controllerAudio(!isAudio) } size="20" className="button-audio" icon={isAudio ? volumeUp : volumeOff} />
                    <Icon size="20" className="button-exit" icon={powerOff} />
                </span>
            </div>
            <header className="bingo-bolao">
                <div className="area-logo">
                    <div><img className="logo-image" alt="Bingo Bolão" src={logo} /></div>
                    BINGO BOLÃO
                </div>
                <div className="area-premium">
                    <div>Prémio da Rodada</div>
                    <div>{gameData ? convertToMoney(gameData.BingoInfoTO.turn_prize) : ''}</div>
                </div>
                <div className="area-premium-acum">
                    <div>Prémio Acumulado</div>
                    <div>{gameData ? convertToMoney(gameData.BingoInfoTO.turn_accum) : ''}</div>
                </div>
            </header>
            <header className="user">
                <div className="user-data">
                    <div><Icon size="48" icon={userCircleO} /></div>
                    {gameData ? gameData.BingoInfoTO.user_nick : ''}
                </div>
                <div className="user-money">
                    <div><strong>Saldo:</strong><span>{gameData ? convertToMoney(gameData.BingoInfoTO.user_credits) : ''}</span></div>
                    <div><strong>Bônus:</strong><span>{gameData ? convertToMoney(gameData.BingoInfoTO.user_bonus) : ''}</span></div>
                    <div><strong>Prêmio:</strong><span>{gameData ? convertToMoney(gameData.BingoInfoTO.user_prize) : ''}</span></div>
                </div>
                <div className="user-button-buy">
                    <div><Icon size="48" icon={shoppingCart} /></div>
                    Comprar
                </div>
            </header>
            {
                gameData ?
                    gameData.BingoInfoTO.user_tickets ?
                        <section className="area-cards">
                            <div className="best-card">
                                <p>Melhor Cartela</p>
                                <Card  />
                            </div>
                            <div className="list-card">
                                <p>Minhas Cartelas - {gameData.BingoInfoTO.user_tickets[0].BingoTicket.length}</p>
                                <div className="list">
                                    {
                                        gameData.BingoInfoTO.user_tickets[0].BingoTicket.map((item, id) => {
                                            return <Card key={`card-list-${id}`} gameBallsServer={gameBallsServer} numbers={item.numbers[0].unsignedByte} />
                                        })
                                    }
                                </div>
                            </div>
                        </section>
                    :
                    ''
                :
                ''
            }
            <section className="area-game">
                <div className="area-game-play">
                    <p>Jogo Atual - {gameData ? 
                                        (/mega/gi).test(gameData.BingoInfoTO.currentGame[0].turn_type) ?
                                            <><Icon size="22" icon={trophy} /> MEGA</>
                                        :
                                             <><Icon size="22" icon={ticket} />  NORMAL </>
                                        : 
                                        ''
                                    }
                    </p>
                    <header className="data-current-game">
                        <div><strong>Número do Jogo:</strong><span>{gameData ? gameData.BingoInfoTO.currentGame[0].id : ''}</span></div>
                        <div><strong>Valor do Jogo:</strong><span>{gameData ? convertToMoney(gameData.BingoInfoTO.currentGame[0].ticket_value) : ''}</span></div>
                        <div><strong>Total de Jogadores:</strong><span>{gameData ? gameData.BingoInfoTO.currentGameTotals[0].total_players : ''}</span></div>
                        <div><strong>Total de Cartelas:</strong><span>{gameData ? gameData.BingoInfoTO.currentGameTotals[0].total_tickets : ''}</span></div>
                        <div><strong>Suas Cartelas:</strong><span>{gameData ? gameData.BingoInfoTO.user_tickets ? ~~gameData.BingoInfoTO.user_tickets[0].BingoTicket.length :  0 : ''}</span></div>
                    </header>
                </div>
                {/* <div className="area-game-chat">
                    Chat
                </div> */}
            </section>

            <section className="server-balls"> 
            
                {gameData ? 
                    <ServerBalls ref={serverballs} gameBallsServer={gameBallsServer} balls={gameData.BingoInfoTO.serverBalls[0].unsignedByte}/>
                :
                    ''
                }
            </section>
        </section>
    )

}

export default Bingo;