import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {isAuthenticated} from '../../service/auth';

const PrivateRoute = ({component:Component, ...rest}) => (
    <Route 
        {...rest} 
        render={props=>
            isAuthenticated() ? 
            (<Component {...props} />) 
            : 
            (<Redirect to={{pathname:'/Login', state:{from: props.location}}} />)
        }  
    />

);

export default PrivateRoute;