import React from 'react';
import  {BrowserRouter, Route, Switch} from 'react-router-dom';

import PrivateRoute from '../component/privateroute/index';

import Login from '../page/login';
import Bingo from '../page/bingo';

const Routes = () => (
    <BrowserRouter>
        <Switch>
            <PrivateRoute exact path="/Bingo" component={Bingo} />
            <Route path="*" component={Login} />
        </Switch>
    </BrowserRouter>
);

export default Routes;