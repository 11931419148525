export const TOKEN_KEY = '@token-game';

export const USER_DATA = '@user-data';

export const isAuthenticated = () => window.localStorage.getItem(TOKEN_KEY) !== null;

export const getToken = () => window.localStorage.getItem(TOKEN_KEY);

export const login = token => {window.localStorage.setItem(TOKEN_KEY, token);};

export const logout = () =>{window.localStorage.removeItem(TOKEN_KEY);window.localStorage.removeItem(USER_DATA);};

export const getUserData = () => JSON.parse(window.localStorage.getItem(USER_DATA));

export const userData = data => { window.localStorage.setItem(USER_DATA, JSON.stringify(data)); }
